import React from 'react';
import {Button, Typography, Row, Col, Card, Avatar} from 'antd';
import {Color} from './ColorConstants'
import Service1 from './static/image/service1.png'
import Service2 from './static/image/service2.png'
import Service3 from './static/image/service3.png'

const {Title, Paragraph} = Typography;


export const Body2 = () => {
  return (
    <div style={{color: Color.main, textAlign: 'center', marginBottom: 120, minHeight: '80vh', marginTop: 120}}
         name={'body2'}>
      <Title level={2} style={{color: Color.main}}>
        두들러스 랩의 서비스
      </Title>
      <div style={{marginBottom: 40, fontSize: 15}}>
        모든 개발 작업은 팀 매니저와 외국 기업에서 근무한 8년 경력 CTO가 <b>직접</b> 관리합니다.
      </div>
      <Row justify="center" align="top" gutter={[32, 32]}>
        <Col md={24} lg={8}>
          <Avatar size={160} style={{backgroundColor: Color.main}}>
            <img src={Service1} alt={'ads'} style={{width: 120}}/>
          </Avatar>
          <Title level={4} style={{margin: 20, fontFamily: 'NanumSquare'}}>
            개발 기획
          </Title>
          <ul style={{fontSize: 15, fontWeight: 300, textAlign: 'left'}}>
            <li>
              사용자 중심의 웹/앱 기획
            </li>
            <li>
              한정된 예산으로 웹/앱 개발을 위한 1:1 상담 진행
            </li>
          </ul>
        </Col>
        <Col md={24} lg={8}>
          <Avatar size={160} style={{backgroundColor: Color.main}}>
            <img src={Service2} alt={'ads'} style={{width: 120}}/>
          </Avatar>
          <Title level={4} style={{margin: 20}}>
            웹사이트 개발
          </Title>
          <div style={{fontSize: 15, fontWeight: 300, textAlign: 'left'}}>
            <li>
              현대적 감각의 웹사이트 개발
            </li>
            <li>
              모바일에서도 완벽 호환
            </li>
            <li>
              SEO(검색 순위) 최적화와 고객 분석 시스템 적용으로 방문 및 매출 극대화
            </li>
          </div>
        </Col>
        <Col md={24} lg={8}>
          <Avatar size={160} style={{backgroundColor: Color.main}}>
            <img src={Service3} alt={'ads'} style={{width: 120}}/>
          </Avatar>
          <Title level={4} style={{margin: 20}}>
            앱 개발
          </Title>
          <div style={{fontSize: 15, fontWeight: 300, textAlign: 'left'}}>
            <li>
              Discord, AirBnB 등 글로벌 대기업이 채택한 React Native로 개발
            </li>
            <li>
              애플과 안드로이드 모두에서 활용 가능한 앱 개발
            </li>
          </div>
        </Col>
      </Row>
    </div>
  );
}
