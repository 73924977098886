import React from 'react';
import {Menu, Row, Col} from 'antd';
import TheDashLogo from "./static/image/TheDash_Logo.png";
import {Color} from './ColorConstants'

// TODO Menu Action


export const Navbar = (props) => {
  return (
    <Row>
      <Col className="logo" style={{float: 'left'}} xs={24} md={8}>
        <img src={TheDashLogo} style={{width: 40, margin: '-10px 10px 0px -20px'}} alt={'logo'}/>
        <span style={{color: '#FFFFFF', fontSize: 20}}>
            {" 두들러스 랩"}
          </span>
      </Col>
      <Col xs={0} md={16}  style={{float: 'right'}}>
        <Menu theme="dark" mode="horizontal" style={{backgroundColor: Color.main, float: 'right', marginRight: -20, color:Color.dark }}>
          <Menu.Item key="1" onClick={() => {
            props.menuClick && props.menuClick(1)
          }}>
            About Us
          </Menu.Item>
          <Menu.Item key="2" onClick={() => {
            props.menuClick && props.menuClick(2)
          }}>
            Service
          </Menu.Item>
          <Menu.Item key="3" onClick={() => {
            props.menuClick && props.menuClick(3)
          }}>
            Process
          </Menu.Item>
          <Menu.Item key="4" onClick={() => {
            props.menuClick && props.menuClick(4)
          }}>
            Portfolio
          </Menu.Item>
          <Menu.Item key="5" onClick={() => {
            props.menuClick && props.menuClick(5)
          }}>
            Contact Us
          </Menu.Item>
        </Menu>
      </Col>
    </Row>
  );
}
