import React from 'react';
import {Menu, Layout, Carousel} from 'antd';
import Banner1 from "./static/image/banner1.jpg";
import Banner2 from "./static/image/banner2.jpg";
import Banner3 from "./static/image/banner3.jpg";

// TODO 나머지 배너 문구 수정

const bannerHeight = '100vh'

export const TopBanner = () => {
  return (
    <Carousel
      // autoplay
      effect="fade"
      dotPosition={'bottom'}
      style={{height: bannerHeight}}
    >
      <div style={{position: 'relative'}}>
        <img
          src={Banner1}
          style={{
            width: '100%',
            height: bannerHeight,
            objectFit: 'cover',
            backgroundColor: '#000',
            opacity: 1,
            filter: 'blur(0px)'
          }} alt={'logo'}
        />
        <div
          style={{
            position: 'relative',
            bottom: bannerHeight,
            height: bannerHeight,
            fontSize: 40,
            color: '#FFF',
            fontWeight: 700,
            background: 'linear-gradient(to top, rgba(0,0,0,0.3) 30%, rgba(255,255,255,0) 60%)'
            // display:'flex',
            // verticalAlign:'center',
            // alignItems:'center'
          }}
        >
          <div style={{
            height: 50,
            position: 'relative',
            bottom: '-30vh',
            textAlign: 'center',
            textShadow: '0 0 35px #000'
          }}>
            저희는 기회를 개발합니다
          </div>
        </div>
      </div>
      <div style={{position: 'relative'}}>
        <img
          src={Banner2}
          style={{
            width: '100%',
            height: bannerHeight,
            objectFit: 'cover',
            backgroundColor: '#000',
            opacity: 1,
            filter: 'blur(0px)'
          }} alt={'logo'}
        />
        <div
          style={{
            position: 'relative',
            bottom: bannerHeight,
            height: bannerHeight,
            fontSize: 40,
            color: '#FFF',
            // backgroundColor: 'rgba(0,0,0,0.1)',
            fontWeight: 700,
            background: 'linear-gradient(to top, rgba(0,0,0,0.4) 30%, rgba(255,255,255,0) 60%)'
            // display:'flex',
            // verticalAlign:'center',
            // alignItems:'center'
          }}
        >
          <div style={{
            height: 50,
            position: 'relative',
            bottom: '-70vh',
            textAlign: 'center'
          }}>
            개발 고민, 두들러스 랩이 해결합니다
          </div>
        </div>
      </div>
      <div style={{position: 'relative'}}>
        <img
          src={Banner3}
          style={{
            width: '100%',
            height: bannerHeight,
            objectFit: 'cover',
            backgroundColor: '#000',
            opacity: 1,
            filter: 'blur(0px)'
          }} alt={'logo'}
        />
        <div
          style={{
            position: 'relative',
            bottom: bannerHeight,
            height: bannerHeight,
            fontSize: 30,
            color: '#FFF',
            // backgroundColor: 'rgba(0,0,0,0.1)',
            fontWeight: 700,
            background: 'linear-gradient(to top, rgba(0,0,0,0.4) 30%, rgba(255,255,255,0) 60%)'
            // display:'flex',
            // verticalAlign:'center',
            // alignItems:'center'
          }}
        >
          <div style={{
            height: 50,
            position: 'relative',
            bottom: '-40vh',
            textAlign: 'center',
            textShadow:'0 0 35px #000, 0 0 35px #000'
          }}>
            검증된 실력, 탄탄한 기획력
          </div>
        </div>
      </div>
    </Carousel>
  );
}
