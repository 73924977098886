/*global kakao*/
import React, {useEffect} from 'react';
import {Layout, Row, Col, Typography} from "antd";
import Scroll from 'react-scroll';
import {Navbar} from './Navbar'
import {TopBanner} from "./TopBanner";
import {Color} from './ColorConstants'
import {Body1} from "./Body1";
import {ContactForm} from "./ContactForm";
import {PhoneOutlined, MailOutlined} from '@ant-design/icons';
import {Body2} from "./Body2";
import {Body3} from "./Body3";
import {Body4} from "./Body4";
import mapImage from "./static/image/map.png";

const {Header, Content, Footer} = Layout;
const {Paragraph} = Typography;

//TODO
// SCROLL 위면 navbar 투명

const scrollToPart = (n) => {
  Scroll.scroller.scrollTo('body' + n, {
    duration: 300,
    delay: 400,
    smooth: true,
    offset: -120,
  });
};

const menuClick = (e) => {
  switch (e) {
    case 1:
      scrollToPart(1)
      break;
    case 2:
      scrollToPart(2)
      break;
    case 3:
      scrollToPart(3)
      break;
    case 4:
      scrollToPart(4)
      break;
    case 5:
      Scroll.animateScroll.scrollToBottom()
      break;
  }
}

function App() {
  return (
    <>
      <Layout className="layout" style={{backgroundColor: Color.main}}>
        <Header className="header" style={{backgroundColor: Color.main, position: 'fixed', zIndex: 10, width: '100%'}}>
          <Navbar menuClick={(e) => {
            menuClick(e)
          }}/>
        </Header>
        <TopBanner/>
        <Content style={{backgroundColor: 'white', padding: '30px 0px', width:'100%'}}>
          <Layout style={{minHeight: 600, maxWidth: 1200, margin: 'auto', backgroundColor: 'white'}}>
            <Row>
              <Col span={16} offset={4}>
                <Body1/>
                <Body2/>
                <Body3/>
                <Body4/>
              </Col>
            </Row>
          </Layout>
        </Content>
        <div style={{
          width: '100%',
          height: 100,
          color: '#FFFFFF',
          margin: 0,
          padding: 0,
          background: 'linear-gradient(to left top, #203864 0%, #203864 50%, #FFFFFF 50%)'
        }}/>
        <Content style={{backgroundColor: Color.main, paddingTop: '20px'}}>
          <Layout style={{minHeight: 250, maxWidth: 1200, margin: 'auto', backgroundColor: Color.main}}>
            <div style={{margin: 'auto'}}>
              <Typography.Title style={{color: 'white', marginTop: 20}} level={3}>
                CONTACT US
              </Typography.Title>
            </div>
            <Row style={{backgroundColor: Color.main, width: '100%', marginTop: 20}} gutter={[0, 32]}>
              <Col
                xs={{span: 22, offset: 1}} md={{span: 8, offset: 4}}
                style={{textAlign: 'left', color: 'white', fontSize: 15}}
              >
                주식회사 더대시 <br/>
                <Paragraph copyable={{text: '경기도 수원시 영통구 광교로 145 2층 209호'}} style={{color: '#FFFFFF'}}>
                  경기도 수원시 영통구 광교로 145 2층 209호
                </Paragraph>
                <PhoneOutlined/><a href="tel:070-5014-5999" style={{color: '#FFFFFF'}}>{'  070-5014-5999'}</a><br/>
                <Paragraph copyable={{text: 'sukin@the-dash.co'}} style={{color: '#FFFFFF'}}>
                  <MailOutlined/> {'  sukin@the-dash.co '}
                </Paragraph>

                <div style={{marginTop: 20}}>오시는 길</div>
                <a href="http://kko.to/fmmb_lTD0" target="_blank">
                  <img src={mapImage} alt="지도" style={{width: '100%', marginTop: 10}}/>
                </a>
                {/*37.29434086260575 127.04564248318412 */}
              </Col>
              <Col xs={{span: 22, offset: 1}} md={{span: 8}}>
                <ContactForm/>
              </Col>
            </Row>
          </Layout>
        </Content>

        <Footer style={{backgroundColor: Color.dark, color: 'white', minHeight: 100}}>
          <Layout style={
            {maxWidth: 1200, margin: 'auto'}}>
            {/*  TODO Typography*/}
            <Row style={{backgroundColor: Color.dark}}>
              <Col xs={24} lg={16}>
                주식회사 더대시 | 대표이사: 김학준 | 개인정보보호최고책임자: 김학준 <br/>
                사업자등록번호: 250-81-01016 <br/>
              </Col>
              <Col xs={24} lg={8} style={{textAlign: 'right', color: 'white'}}>
                070-5014-4999 <br/>
                주말 및 공휴일 휴무
              </Col>
            </Row>
            {/*  TODO SNS (카카오톡) 버튼?*/}
          </Layout>
        </Footer>
      </Layout>
    </>
  );
}

export default App;
