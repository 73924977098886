import React from 'react';
import {Button, Typography} from 'antd';
import {Color} from './ColorConstants'

const {Title} = Typography;
// TODO

export const Body1 = () => {
  return (
    <div style={{color: Color.main, textAlign: 'center', marginBottom: 120, marginTop:120, minHeight:'80vh'}} name={'body1'}>
      <Title level={2} style={{color: Color.main}}>
        기획부터 개발까지 프로답게
      </Title>
      <div style={{color: Color.main, fontSize: 20, marginBottom: 25}}>
        두들러스 랩은 웹과 앱을 한번에 개발하는 React Native 기술로 <br/>
        빠른 속도와 낮은 비용으로 여러분의 사업 기회를 열어드립니다 <br/>
      </div>
      <div style={{color: Color.main, fontSize: 20, marginBottom: 25}}>
        정부지원 사업부터 스타트업과 대기업 외주까지, <br/>
        고객사와 시장에게 검증받았습니다. <br/>
      </div>
      <div style={{color: Color.main, fontSize: 20, marginBottom: 25}}>
        정부지원 사업부터 스타트업과 대기업 외주까지, <br/>
        고객사와 시장에게 검증받았습니다. <br/>
      </div>
      {/*TODO: 여기에 발주사 로고 목록 */}
      <a href='/portfolio.pdf' download>
        <Button size={'large'} style={{color:Color.main, borderColor:Color.main}}>
          회사 소개서 보기
        </Button>
      </a>
      {/*<div style={{backgroundColor: '#000', height: 60, margin: '10px 0px'}}/>*/}
    </div>
  );
}
