import React, {useState} from 'react';
import {Form, Input, Button, Checkbox, notification, Row, Col} from 'antd';
import emailjs from 'emailjs-com';

export const ContactForm = (props) => {
  console.log(process.env.REACT_APP_EMAILJS_PASS)

  const [agreed, setAgreed] = useState(false)
  const [contact, setContact] = useState({
    "name": "",
    "company": "",
    "phone": "",
    "email": "",
    "price": "",
    "content": ""
  })

  const successNotification = () => {
    notification.success({
      message: '곧 연락 드리겠습니다',
      description: '문의 주셔서 감사합니다! \n 확인 후 2 영업일 이내에 연락 드리겠습니다.',
      placement: 'bottomRight'
    });
  };

  const formErrorNotification = () => {
    notification.error({
      message: '양식을 확인해주세요',
      description: '성함, 연락처, 문의내용, 개인정보 저장 동의는 필수입니다.',
      placement: 'bottomRight'
    });
  };

  const sendEmail = () => {
    if (contact.name === "" || contact.phone === "" || contact.content === "" || agreed === false) {
      formErrorNotification()
      return
    }
    emailjs.send('thedash', 'template_PlKCsddF', contact, process.env.REACT_APP_EMAILJS_PASS)
      .then((result) => {
        successNotification()
      }, (error) => {
        console.log(error);
        alert('죄송합니다 \n 상담 접수에 실패했습니다. 전화나 이메일로 연락주시면 최선을 다해 상담해드리겠습니다.')
      });

    setContact({
      "name": "",
      "company": "",
      "phone": "",
      "email": "",
      "price": "",
      "content": ""
    })
    setAgreed(false)
  }

  return (
    <div style={{minHeight: 200, color: 'white'}}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8}>
          <div>
            성함 *
          </div>
          <Input value={contact.name} onChange={(e) => (setContact({...contact, 'name': e.target.value}))}/>
        </Col>
        <Col xs={24} sm={16}>
          <div>
            회사
          </div>
          <Input value={contact.company} onChange={(e) => (setContact({...contact, 'company': e.target.value}))}/>
        </Col>
        <Col xs={24} sm={8}>
          <div>
            연락처 *
          </div>
          <Input value={contact.phone} onChange={(e) => (setContact({...contact, 'phone': e.target.value}))}/>
        </Col>
        <Col xs={24} sm={16}>
          <div>
            이메일
          </div>
          <Input value={contact.email} onChange={(e) => (setContact({...contact, 'email': e.target.value}))}/>
        </Col>
        <Col span={24}>
          <div style={{margin: '10 0 0 0'}}>
            <div>
              문의하실 내용 *
            </div>
            <Input.TextArea
              placeholder="편하게 적어 주시면 연락 드리겠습니다"
              autoSize={{minRows: 4, maxRows: 6}}
              allowClear
              value={contact.content}
              onChange={(e) => (setContact({...contact, 'content': e.target.value}))}
            />
          </div>
        </Col>
        <Col span={24}>
          <div>
            책정 예산
          </div>
          <Input value={contact.price} onChange={(e) => (setContact({...contact, 'price': e.target.value}))}/>
        </Col>
        <Col span={24}>
          <Checkbox
            style={{color: '#FFFFFF', fontSize: 14, marginTop: 5}}
            onChange={(e) => {
              setAgreed(e.target.checked)// TODO https://motion.ant.design/api/tween-one 에니메이션 추가 ..
            }}
          >
            상담을 위한 개인정보 저장에 동의합니다 *
          </Checkbox>
        </Col>
        <Col span={24}>
          <Button
            block ghost={true} style={{background: "white", borderColor: "white", color: 'white', marginTop: 10}}
            onClick={() => sendEmail()}
          >
            보내기
          </Button>
        </Col>
      </Row>
    </div>);
}
