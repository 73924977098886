import React from 'react';
import {Button, Typography, Divider, Row, Col, Card} from 'antd';
import {Color} from './ColorConstants'

import Example1_1 from '../src/static/image/Ex1_1.png'
import Example1_2 from '../src/static/image/Ex1_2.png'
import Example2_1 from '../src/static/image/Ex2_1.png'
import Example2_2 from '../src/static/image/Ex2_2.png'
import Example3_1 from '../src/static/image/Ex3_1.png'
import Example3_2 from '../src/static/image/Ex3_2.png'
import Example4_1 from '../src/static/image/Ex4_1.png'
import Example4_2 from '../src/static/image/Ex4_2.png'
import Example4_3 from '../src/static/image/Ex4_3.png'

const {Title} = Typography;


export const Body4 = () => {
  return (
    <div style={{color: Color.main, textAlign: 'center', marginBottom: 90, minHeight: '100vh'}} name={'body4'}>
      <Title level={2} style={{color: Color.main}}>
        두들러스 랩의 개발 이력
      </Title>
      <div style={{marginBottom: 40, fontSize: 15}}>
        대기업, 스타트업, 정부지원사업 등 다양한 고객사가 저희의 앱과 웹을 사용합니다 <br/>
        풍부한 경험과 탄탄한 실력으로 도와드리겠습니다.
      </div>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={10} style={{margin: 'auto', position: 'relative'}}>
          <div style={{height: 280}}>
            <div style={{position: 'absolute', margin: 'auto', left: 0, right: 0, paddingLeft: 30, minWidth: 280}}>
              <img src={Example1_1} alt="Example" style={{height: 200, position: 'relative'}}/>
              <img src={Example1_2} alt="Example" style={{height: 200, position: 'relative', top: 50, right: 50}}/>
            </div>
          </div>
        </Col>
        <Col xs={24} md={10} style={{margin: 'auto'}}>
          <Title level={4} style={{color: Color.main}}>
            코로나있다
          </Title>
          <div style={{fontSize: 15, fontWeight: 300}}>
            유저 친화적인 디자인과 사용법, <br/>
            빅 데이터와 데이터 마이닝을 활용하여<br/>
            출시 2주만에 이용자가 600만을 돌파한 프로젝트입니다
          </div>
        </Col>
        <Col xs={0} md={4} style={{margin: 'auto'}}/>
      </Row>
      <Divider/>
      <Row gutter={[16, 16]}>
        <Col xs={0} md={4} style={{margin: 'auto'}}/>
        <Col xs={24} md={10} style={{margin: 'auto'}}>
          <Title level={4} style={{color: Color.main}}>
            Dash
          </Title>
          <div style={{fontSize: 15, fontWeight: 300}}>
            미국에서 런칭한 커뮤니티 서비스로 <br/>
            실시간 채팅 기능 개발로 연간 800만원의 <br/>
            고정 지출비를 절감했습니다.
          </div>
        </Col>
        <Col xs={24} md={10} style={{margin: 'auto', position: 'relative'}}>
          <div style={{height: 250}}>
            <div style={{position: 'absolute', margin: 'auto', left: 0, right: 0, paddingLeft: 30, minWidth: 280}}>
              <img src={Example2_1} alt="Example" style={{height: 200, position: 'relative'}}/>
              <img src={Example2_2} alt="Example" style={{height: 200, position: 'relative', top: 50, right: 50}}/>
            </div>
          </div>
        </Col>
        <Col xs={0} md={8} style={{margin: 'auto'}}/>
      </Row>
      <Divider/>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={10} style={{margin: 'auto', position: 'relative'}}>
          <div style={{height: 250}}>
            <div style={{position: 'absolute', margin: 'auto', left: 0, right: 0, paddingLeft: 30, minWidth: 280}}>
              <img src={Example3_1} alt="Example" style={{height: 200, position: 'relative'}}/>
              <img src={Example3_2} alt="Example" style={{height: 200, position: 'relative', top: 50, right: 50}}/>
            </div>
          </div>
        </Col>
        <Col xs={24} md={10} style={{margin: 'auto'}}>
          <Title level={4} style={{color: Color.main}}>
            Nalla
          </Title>
          <div style={{fontSize: 15, fontWeight: 300}}>
            정부지원사업 수혜 대상 기업으로,<br/>
            서울시 따릉이 재배치 관련 시스템입니다. <br/>
            높은 만족도로, 5회에 걸쳐 추가 계약을 완료했습니다.
          </div>
        </Col>
        <Col xs={0} md={4} style={{margin: 'auto'}}/>
      </Row>

      <Divider/>
      <Row gutter={[16, 16]}>
        <Col xs={0} md={4} style={{margin: 'auto'}}/>
        <Col xs={24} md={10} style={{margin: 'auto'}}>
          <a href='/portfolio.pdf' download>
            <Title level={4} style={{color: Color.main}}>
              더 보기
            </Title>
            <div style={{fontSize: 15, fontWeight: 300}}>
              두들러스 랩의 작품을 확인하시려면 클릭! <br/>
            </div>
          </a>
        </Col>
        <Col xs={24} md={10} style={{margin: 'auto', position: 'relative'}}>
          <a href='/portfolio.pdf' download>
            <div style={{height: 300}}>
              <div style={{
                position: 'absolute',
                margin: 'auto',
                left: 0,
                right: 0,
                paddingLeft: 0,
                width: 150,
                overflow: 'hidden'
              }}>
                <img src={Example4_1} alt="Example"
                     style={{height: 150, position: 'relative', top: 20, right: 60, zIndex: 2}}/>
                <img src={Example4_2} alt="Example" style={{height: 150, position: 'relative', top: -145, right: 0}}/>
                <img src={Example4_3} alt="Example" style={{height: 100, position: 'relative', top: -200, right: 0}}/>
              </div>
            </div>

          </a>

        </Col>
        <Col xs={0} md={8} style={{margin: 'auto'}}/>
      </Row>
    </div>
  );
}
