import React from 'react';
import {Button, Typography, Steps, Divider} from 'antd';
import {Color} from './ColorConstants'
import {CheckCircleFilled} from '@ant-design/icons';

const {Title} = Typography;
const {Step} = Steps;
// TODO

const StepperIcon = <CheckCircleFilled style={{color: Color.main, fontSize: '30px'}}/>
const BlueDiv = (props) =>
  <div style={{color: Color.main}}>
    {props.children}
  </div>


export const Body3 = () => {
  return (
    <div style={{color: Color.main, textAlign: 'center', marginBottom: 90, minHeight:'80vh'}} name={'body3'}>
      <Title level={2} style={{color: Color.main}}>
        두들러스 랩이 일하는 방법
      </Title>
      <div style={{marginBottom: 40, fontSize: 15}}>
        모든 개발 작업은 팀 매니저와 해외 기업에서 근무한 8년 경력 CTO가 '직접' 관리합니다.
      </div>
      <Steps current={10} direction="vertical" style={{margin:'auto', maxWidth:450}}>
        <Step
          title={<BlueDiv>Step 1. 견적 상담</BlueDiv>}
          description={<BlueDiv>모든 문의는 팀장급 매니저가 직접 견적을 분석 및 평가합니다.</BlueDiv>}
          icon={StepperIcon}
        />
        <Step
          title={<BlueDiv>Step 2. 개발 계획 확정</BlueDiv>}
          description={<BlueDiv>
            두들러스 랩의 개발 경력과 DB를 바탕으로, <br/>
            고객의 예산과 니즈에 가장 알맞는 계획을 제안해드립니다.
          </BlueDiv>}
          icon={StepperIcon}
        />
        <Step
          title={<BlueDiv>Step 3. 개발</BlueDiv>}
          description={<BlueDiv>
            CTO가 관리하고, 팀장급 개발자가 주도하여 개발을 진행합니다.<br/>
            고객사와의 지속적인 소통으로 최고의 결과물을 위해 노력합니다.
          </BlueDiv>}
          icon={StepperIcon}
        />
        <Step
          title={<BlueDiv>Step 4. QA-품질 보장</BlueDiv>}
          description={<BlueDiv>
            개발 후, 버그 테스트와 고객사의 피드백을 받아서 수정합니다. <br/>
            고객은 개발자와 함께 직접 앱을 테스트하고, 수정 의견을 반영합니다.
          </BlueDiv>}
          icon={StepperIcon}
        />
        <Step
          title={<BlueDiv>Step 5. 결과물 인계 및 추가 계약 </BlueDiv>}
          description={<BlueDiv>작업물을 고객에게 인계하고 추가 개발 계획을 논의합니다.</BlueDiv>}
          icon={StepperIcon}
        />

      </Steps>
    </div>
  );
}
